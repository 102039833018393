<template>
  <div>
    <div class="background-circle left-circle" />
    <div class="background-circle right-circle" />
    <a href="https://www.teamgather.co">
      <g-image :src="gatherLogo" size="small" class="logo" alt="Gather logo" />
    </a>
    <loading loading />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gImage from '@/components/v2/image.vue'
import loading from '@/components/v2/loading.vue'

import gatherLogo from '@/assets/img/gather-logo-blue.png'

export default {
  components: { gImage, loading },
  data() {
    return { gatherLogo, showLogout: false, timer: null }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
    }),
  },
  watch: {
    currentUser: {
      handler() {
        this.rerouteUser()
      },
    },
  },
  mounted() {
    this.rerouteUser()
  },
  methods: {
    rerouteUser() {
      if (this.currentUser && this.currentUser.organizationId) {
        this.$router.push({ name: 'moments' })
        return
      }

      if (this.currentUser && !this.currentUser.organizationId) {
        this.$router.push({ name: 'sign_up_onboarding' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.logout-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.logo {
  display: inline !important;
  max-width: 10em;
  margin: 1em 0 0 1em;
}

.background-circle {
  position: fixed;
  z-index: -1;
  width: 80vh;
  height: 80vh;
  border-radius: 100%;
  opacity: 0.5;
  transition: width 1s, height 1s;
}

.left-circle {
  top: -250px;
  right: auto;
  bottom: auto;
  left: -400px;
  background-color: @light-green;
}

.right-circle {
  top: auto;
  right: -400px;
  bottom: -200px;
  left: auto;
  background-color: @light-blue;
}

@media screen and (max-width: 1000px) {
  .background-circle {
    width: 70vh;
    height: 70vh;
  }
}

@media screen and (max-width: 768px) {
  .background-circle {
    width: 0;
    height: 0;
    transition: width 2s, height 2s;
  }
}
</style>
